import type { StoreActionApi } from 'react-sweet-state';
import { createStore, createHook } from 'react-sweet-state';

import { expValEquals } from '@confluence/feature-experiments';
import { useBooleanFeatureFlag, useMultivariantFeatureFlag } from '@confluence/session-data';

import type { UnreadCommentQuery_comments_nodes as UnreadCommentNode } from '../__types__/UnreadCommentQuery';
import type {
	MarkerRefToCommentIdMap,
	UnreadInlineComment,
	UnreadInlineCommentsState,
} from '../types/unreadCommentsTypes';

const UNREAD_COMMENTS_FF = 'confluence.frontend.navigate.unread.comments';

const initialState: UnreadInlineCommentsState = {
	unreadCommentsListState: [],
	readCommentsListState: new Set(),
	shouldUpdateUnreadComments: false,
	shouldUpdateUnreadCommentsOnFirstRender: true,
	markerRefToCommentIdMap: {},
	allInlineComments: [],
};

const actions = {
	updateUnreadCommentsListState:
		(
			transform: (
				prevUnreadCommentsListState: UnreadInlineComment[],
				markerRefToCommentIdMap: MarkerRefToCommentIdMap,
			) => UnreadInlineComment[],
		) =>
		({ setState, getState }: StoreActionApi<UnreadInlineCommentsState>) => {
			const { unreadCommentsListState, markerRefToCommentIdMap } = getState();
			setState({
				unreadCommentsListState: transform(unreadCommentsListState, markerRefToCommentIdMap),
			});
		},
	updateReadCommentsListState:
		(transform: (prevReadCommentsListState: Set<string>) => Set<string>) =>
		({ setState, getState }: StoreActionApi<UnreadInlineCommentsState>) => {
			const { readCommentsListState } = getState();
			setState({
				readCommentsListState: transform(readCommentsListState),
			});
		},
	updateUnreadComments:
		(shouldUpdateUnreadComments: boolean) =>
		({ setState }: StoreActionApi<UnreadInlineCommentsState>) => {
			setState({ shouldUpdateUnreadComments });
		},
	updateUnreadCommentsOnFirstRender:
		(shouldUpdateUnreadCommentsOnFirstRender: boolean) =>
		({ setState }: StoreActionApi<UnreadInlineCommentsState>) => {
			setState({ shouldUpdateUnreadCommentsOnFirstRender });
		},
	updateMarkerRefToCommentIdMap:
		(transform: (markerRefToCommentIdMap: MarkerRefToCommentIdMap) => MarkerRefToCommentIdMap) =>
		({ setState, getState }: StoreActionApi<UnreadInlineCommentsState>) => {
			const { markerRefToCommentIdMap } = getState();
			setState({
				markerRefToCommentIdMap: transform(markerRefToCommentIdMap),
			});
		},
	updateAllInlineComments:
		(allInlineComments: UnreadCommentNode[]) =>
		({ setState }: StoreActionApi<UnreadInlineCommentsState>) => {
			setState({ allInlineComments });
		},
};

const UnreadInlineCommentsStore = createStore({
	initialState,
	actions,
	name: 'UnreadInlineCommentsStore',
});

export const useUnreadInlineComments = createHook(UnreadInlineCommentsStore);

export const useUnreadCommentsIsEnabled = () => {
	const { cohort: rapCohort } = useMultivariantFeatureFlag(
		'confluence.frontend.renderer.annotation.provider.inline.comments',
		['annotation-provider', 'not-enrolled', 'query-selectors'],
		'not-enrolled',
		true,
	);
	const isRendererAnnotationProviderEnabled = rapCohort === 'annotation-provider';

	return (
		useBooleanFeatureFlag(UNREAD_COMMENTS_FF) &&
		isRendererAnnotationProviderEnabled &&
		!expValEquals<'control' | 'dot' | 'count'>('unread_comments_navigation', 'cohort', 'control')
	);
};
